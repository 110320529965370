<template>
  <div class="Main">
    <Home
      v-if="bShowHome"
      v-on:showScanner="showScanner"
      v-on:deleteScan="deleteScan"
      v-on:editerScan="editerScan"
      v-bind:Scans="allScans"
      v-bind:returnMessage="returnMessage"
      v-bind:classMessage="classMessage"
    ></Home>
    <BarcodeScanner
      v-if="bShowScanner"
      v-on:appendMessage="appendMessage" 
      v-on:hideScanner="hideScanner"
    ></BarcodeScanner>
  </div>
</template>

<script>
import DBR from "../dbr";
import BarcodeScanner from "./BarcodeScanner";
import Home from "./Home";
export default {
  name: "Main",
  props: {
  },
  data() {
    return {
      resultValue: "",
      allScans:[],
      libLoaded: false,
      bShowScanner: false,
      bShowHome: true,
      returnMessage: false,
      classMessage: false,
    };
  },
  async mounted() {
    //Load the library on page load to speed things up.
    try {
      //DBR.BarcodeReader._bUseFullFeature = true;
      await DBR.BarcodeScanner.loadWasm();
      this.libLoaded = true;
    } catch (ex) {
      alert(ex.message);
      throw ex;
    }
    
    fetch("https://21sofcot.urevent.fr/Scanapi/registeredscan/" + this.$auth.user.iduser)
      .then(
        (response) => response.json()
        )
      .then((json) => {
        if(json===405){
          this.allScans = [];
        }else{
          this.allScans = json
        }
      });
  },
  updated() {},

  components: {
    BarcodeScanner,
    Home,
  },

  methods: {

    appendMessage(message) {
      switch (message.type) {
        case "result":
          this.resultValue = message.format + ": " + message.text;
          this.$auth.fetch(
            "https://21sofcot.urevent.fr/Scanapi/addregisteredscan", 
            {
              method: "POST",
              body: { iduser:this.$auth.user.iduser, externalid:message.text }
            }
          ).then(response => {
            if(response){
              if(response.status){
                if (response.status === 407) {
                  this.returnMessage = response.message;
                  this.classMessage = 'warning';
                  setTimeout(() => {
                      this.returnMessage = ""
                  }, 4000)
                }else if(response.status === 406){
                  this.returnMessage = response.message;
                  this.classMessage = 'error';
                  setTimeout(() => {
                      this.returnMessage = ""
                  }, 4000)
                }
              }else{
                fetch("https://21sofcot.urevent.fr/Scanapi/registeredscan/" + this.$auth.user.iduser)
            .then(
              (response) => response.json()
              )
            .then((json) => {
              if(json===405){
                this.allScans = [];
              }else{
                this.allScans = json
              }
            });
                this.returnMessage = "Contact ajouté";
                this.classMessage = 'success';
                  setTimeout(() => {
                      this.returnMessage = ""
                  }, 4000)
              }
            }
          })
          break;
        case "error":
          this.resultValue =
            "Error Occurred! Check the error message in 'All results'!";
          break;
        default:
          break;
      }
    },

    resetalert(){              
      this.returnMessage = false;
      this.classMessage = false;
    console.log('off');
    },


    editerScan(scan){
      this.$auth.fetch("https://21sofcot.urevent.fr/Scanapi/updateregisteredscan/",
        {
          method: "POST",
          body: { idscan:scan.idscan, scancoments:scan.scan_coments }
        })
      .then(response => {
        if(response){
          if(response.status){
            if (response.status === 406) {
              this.returnMessage = response.message;
              this.classMessage = 'warning';
                  setTimeout(() => {
                      this.returnMessage = ""
                  }, 4000)
              
            }else if(response.status === 405){
              this.returnMessage = response.message;
              this.classMessage = 'error';
                  setTimeout(() => {
                      this.returnMessage = ""
                  }, 4000)
            }
          }else{
            fetch("https://21sofcot.urevent.fr/Scanapi/registeredscan/" + this.$auth.user.iduser)
            .then(
              (response) => response.json()
              )
            .then((json) => {
              if(json===405){
                this.allScans = [];
              }else{
                this.allScans = json
              }
            });
            this.returnMessage = "Mise à jour réussie";
            this.classMessage = 'success';
                  setTimeout(() => {
                      this.returnMessage = ""
                  }, 4000)
          }
            
        }else{
          this.returnMessage = 'erreur de connexion';
          this.classMessage = 'error';
        }
      })
    },


    deleteScan(idscan){
      this.$auth.fetch("https://21sofcot.urevent.fr/Scanapi/delregisteredscan/" + idscan.idscan)
      .then(response => {
        if(response){
            fetch("https://21sofcot.urevent.fr/Scanapi/registeredscan/" + this.$auth.user.iduser)
            .then(
              (response) => response.json()
              )
            .then((json) => this.allScans = json);
            this.returnMessage = "Contact supprimé";
            this.classMessage = 'error';
                  setTimeout(() => {
                      this.returnMessage = ""
                  }, 4000)
        }else{
          this.returnMessage = 'Erreur de connexion';
          this.classMessage = 'error';
                  setTimeout(() => {
                      this.returnMessage = ""
                  }, 4000)
        }
      })
    },

    showScanner() {
      this.bShowScanner = true;
      this.bShowHome= false;
    },

    hideScanner() {
      this.bShowHome= true;
      this.bShowScanner = false;
    },

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>