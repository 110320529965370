<template>
  <transition name="modal">
    <div class="msg_overlay text-center">
      <h3 :class="classmodalMessage">
        <i
          class="bi bi-bell"
          style="font-size: 73px; display: block"
          :class="classmodalMessage"
        ></i
        ><br />
        {{ ModalMessage }}
      </h3>
      <h1 class="white my-4">{{ contentmodal.registered_firstname }} {{ contentmodal.registered_lastname }}</h1>

      <button class="btn btn_icontools_green mx-4 success" v-if="typemodal=='delete'" @click="$emit('delScan', { idscan: contentmodal.idscan })"><i class="bi bi-trash"></i></button>
      <button class="btn btn_myicon mx-4" v-if="typemodal=='delete'" @click="$emit('close')"><i class="bi bi-x"></i></button>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  props: 
    ["ModalMessage",
    "classmodalMessage",
    "typemodal","contentmodal"]
  ,
  watch: {},
};
</script>